import { useCallback } from 'react';
import { useState, useEffect } from 'react';
import Video, { LocalVideoTrack, LocalAudioTrack } from 'twilio-video';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useLocalVideoToggle() {
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
  } = useVideoContext();
  const videoTrack = localTracks.find(track => track.name === 'camera') as LocalVideoTrack;

  const toggleVideoEnabled = useCallback(() => {
    let cameraValue;
    const mirrorCameraButton = document.getElementsByClassName('mirror-camera-button')[0];
    const videoButton = document.getElementsByClassName('video-button')[0];

    if (videoButton.classList.contains('normal')) {
      videoButton.classList.remove('normal');
      videoButton.classList.add('needs-attention');
    } else {
      videoButton.classList.remove('needs-attention');
      videoButton.classList.add('normal');
    }

    if (mirrorCameraButton !== undefined) {
      mirrorCameraButton.classList.add('disabled');
    }

    if (videoTrack || videoButton.classList.contains('needs-attention')) {
      if (localParticipant) {
        localParticipant.videoTracks.forEach(trackPublication => {
          const localTrackPublication = localParticipant.unpublishTrack(trackPublication.track);
          localParticipant.emit('trackUnpublished', localTrackPublication);
          trackPublication.track.stop();
        });
      }

      if (videoTrack !== undefined) {
        videoTrack.stop();
      }
    } else {
      if (mirrorCameraButton !== undefined) {
        cameraValue = mirrorCameraButton.getAttribute('front-camera') || 'true';
        Video.createLocalVideoTrack({
          facingMode: { exact: cameraValue == 'true' ? 'user' : 'environment' },
          frameRate: 24,
          height: 720,
          width: 1280,
        }).then((track: LocalVideoTrack) => {
          if (localParticipant) {
            localParticipant.publishTrack(track);
          }
        });
      } else {
        getLocalVideoTrack().then((track: LocalVideoTrack) => {
          if (localParticipant) {
            localParticipant.publishTrack(track);
          }
        });
      }
    }

    if (videoButton.classList.contains('normal') && mirrorCameraButton !== undefined) {
      mirrorCameraButton.classList.remove('disabled');
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack]);

  return [!!videoTrack, toggleVideoEnabled] as const;
}
