import React from 'react';
import { styled } from '@material-ui/core/styles';

import Controls from './components/Controls/Controls';
import LocalVideoPreview from './components/LocalVideoPreview/LocalVideoPreview';
import MenuBar from './components/MenuBar/MenuBar';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';

import useRoomState from './hooks/useRoomState/useRoomState';

import './App.scss';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const Main = styled('main')({
  height: '100vh',
  width: '100%',
  position: 'relative',
});

export default function App() {
  const roomState = useRoomState();

  return (
    <Container className={roomState === 'disconnected' ? 'join-room-screen' : 'room-screen'}>
      <div className="video-wrapper">
        <Main>
          {roomState === 'disconnected' ? <LocalVideoPreview /> : <Room />}
          <Controls />
        </Main>
        {roomState === 'disconnected' ? <MenuBar /> : null}
      </div>

      <ReconnectingNotification />
    </Container>
  );
}
