import { useCallback } from 'react';
import { useState, useEffect } from 'react';
import Video, { LocalVideoTrack, LocalAudioTrack } from 'twilio-video';
import useVideoContext from '../useVideoContext/useVideoContext';

export default function useLocalCameraFlip() {
  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
  } = useVideoContext();
  const videoTrack = localTracks.find(track => track.name === 'camera') as LocalVideoTrack;

  const toggleCameraFlip = useCallback(() => {
    let cameraValue;
    let userAgentDevice = 'IOS';
    const mirrorCameraButton = document.getElementsByClassName('mirror-camera-button')[0];
    const videoButton = document.getElementsByClassName('video-button')[0];
    const userAgent = navigator.userAgent || navigator.vendor;

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      userAgentDevice = 'IOS';
    } else {
      // Android & Windows Phone
      userAgentDevice = 'Android';
    }

    if (mirrorCameraButton !== undefined && !mirrorCameraButton.classList.contains('disabled')) {
      const dataCameraValue = mirrorCameraButton.getAttribute('front-camera') || 'true';
      mirrorCameraButton.setAttribute('front-camera', dataCameraValue == 'true' ? 'false' : 'true');
      cameraValue = mirrorCameraButton.getAttribute('front-camera');
      mirrorCameraButton.classList.add('disabled');

      // unpublish existing open camera videos for Android & Widnows Phones.
      if (userAgentDevice === 'Android' && localParticipant) {
        // alert(userAgentDevice);
        localParticipant.videoTracks.forEach(trackPublication => {
          const localTrackPublication = localParticipant.unpublishTrack(trackPublication.track);
          localParticipant.emit('trackUnpublished', localTrackPublication);
          trackPublication.track.stop();
        });
      }

      Video.createLocalVideoTrack({
        facingMode: { exact: cameraValue == 'true' ? 'user' : 'environment' },
        frameRate: 24,
        height: 720,
        width: 1280,
      }).then(function(localVideoTrack) {
        // unpublish existing open camera videos for IOS.
        if (userAgentDevice === 'IOS' && localParticipant) {
          // alert(userAgentDevice);
          localParticipant.videoTracks.forEach(trackPublication => {
            const localTrackPublication = localParticipant.unpublishTrack(trackPublication.track);
            localParticipant.emit('trackUnpublished', localTrackPublication);
            trackPublication.track.stop();
          });
        }

        // publish switched camera.
        localParticipant.publishTrack(localVideoTrack);
        mirrorCameraButton.classList.remove('disabled');
      });
    }
  }, [localParticipant]);

  return [toggleCameraFlip] as const;
}
