import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import FlipCamera from '@material-ui/icons/FlipCameraAndroid';
import FlipCameraOff from '@material-ui/icons/FlipCameraIos';

import clsx from 'clsx';

import useLocalCameraFlip from '../../../hooks/useLocalCameraFlip/useLocalCameraFlip';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1),
    },
  })
);

export default function ToggleFlipCameraButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const [toggleCameraFlip] = useLocalCameraFlip();

  return (
    <Tooltip
      title='Flip Camera'
      placement="top"
      PopperProps={{ disablePortal: true }}
      className={clsx('mirror-camera-button')}
    >
      <Fab className={classes.fab} onClick={toggleCameraFlip} disabled={props.disabled} data-cy-audio-toggle>
        {<FlipCameraOff />}
      </Fab>
    </Tooltip>
  );
}
