import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';

import EndCallButton from './EndCallButton/EndCallButton';
import ToggleAudioButton from './ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from './ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from './ToogleScreenShareButton/ToggleScreenShareButton';
import ToggleFlipCameraButton from './ToggleFlipCameraButton/ToggleFlipCameraButton';
import ToggleFullscreenButton from '../ToggleFullScreenButton/ToggleFullScreenButton';
import { useEffect } from 'react';

import useLocalVideoToggle from '../../hooks/useLocalVideoToggle/useLocalVideoToggle';

import { BrowserView, MobileView, isBrowser, isMobile, isIOS } from 'react-device-detect';

import useIsUserActive from './useIsUserActive/useIsUserActive';
import useRoomState from '../../hooks/useRoomState/useRoomState';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      position: 'absolute',
      right: '50%',
      transform: 'translate(50%, 0px)',
      bottom: '50px',
      zIndex: 1,
      opacity: 1,
      visibility: 'visible',
    },
  })
);

export default function Controls() {
  const classes = useStyles();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const isUserActive = useIsUserActive();
  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();

  return (
    <div className={clsx(classes.container, 'showControls', 'buttons-wrapper')}>
      {roomState !== 'disconnected' && (
        <>
          <ToggleScreenShareButton disabled={isReconnecting} />
        </>
      )}

      <ToggleAudioButton disabled={isReconnecting} />
      {roomState !== 'disconnected' && (
        <>
          <EndCallButton />
        </>
      )}
      <ToggleVideoButton disabled={isReconnecting} />

      {roomState !== 'disconnected' && (
        <>
          <BrowserView>
            <ToggleFullscreenButton />
          </BrowserView>
          <MobileView>
            <ToggleFlipCameraButton />
          </MobileView>
        </>
      )}
    </div>
  );
}
